@import url(https://fonts.googleapis.com/css2?family=Cabin&family=Press+Start+2P&family=Prompt:wght@600&display=swap);
html,
body,
#root,
.main {
  height: 100vh;
  width: 100vw;
  margin: 0px;
  font-family: prompt;
}

:root {
  --almost-white: rgb(230, 217, 185);
  --almost-black: rgb(28, 28, 28);
  --forest-green: rgb(31, 61, 12);
  --darker-green: rgb(1, 40, 1);
  --pumpkin: rgb(160, 82, 45);
  --mustard: rgb(151, 120, 25);
  --lavender: rgb(90, 84, 121);
  --btn-grey: rgb(36, 35, 35);
  --polaroid-background: rgb(236, 224, 192);

  --announcement-height: 40rem;
  --footer-height: 2.5rem;
  --mobile-announcement-height: calc(100vh - 4rem - 120.5px);

  --polaroid-width: calc(0.35 * 50vh);
}

.main {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.main-container {
  height: 100%;
  width: 100%;
  position: relative;

  background-position-x: -200px;
  background-position-y: -200px;
  background-size: 1800px;
  background-repeat: no-repeat;

  -webkit-user-select: none;

          user-select: none;
  overflow: hidden;
}

.background-container {
  height: 100%;
  width: 100%;
}

.landing-main {
  height: 100%;
  width: 100%;
  display: flex;

  -webkit-user-select: none;

          user-select: none;
  overflow: hidden;
}

.polaroid {
  position: absolute;
  left: calc(50% - calc(0.35 * 50vh));
  left: calc(50% - var(--polaroid-width));
  right: calc(50% + calc(0.35 * 50vh));
  right: calc(50% + var(--polaroid-width));
  top: 15%;

  height: 50%;
  width: auto;

  box-shadow: -2px 2px 3px 1px rgb(0 0 0 / 30%);
}
.polaroid-text {
  position: absolute;
  top: calc(66%);
  width: calc(2 * calc(0.35 * 50vh));
  width: calc(2 * var(--polaroid-width));
  left: calc(50% - calc(0.35 * 50vh));
  left: calc(50% - var(--polaroid-width));
  text-align: center;
  color: rgb(230, 217, 185);
  color: var(--almost-white);

  font-size: 1.2rem;
}
.polaroid-text a {
  text-decoration: none;
  color: rgb(230, 217, 185);
  color: var(--almost-white);
}
.polaroid-text:hover a {
  text-decoration: underline;
}

.background-wrapper {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
}
.background-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}

.nav-element {
  text-decoration: none;
  margin: 1rem;
  padding: 1rem;
  color: white;
  cursor: pointer;
}

.LWU-nav {
  height: 100%;

  display: flex;
  flex-direction: column;

  font-family: prompt;
}

.LWU-nav .cohort-logo {
  padding: 0;
  margin: 0;
}

.LWU-nav-elements {
  box-sizing: border-box;
  display: flex;

  flex-direction: column;
  align-items: center;
}

.cohort-logo {
  margin-right: 4rem;
}

.nav-element:hover {
  background-color: rgb(65, 65, 65, 0.5);
  border-radius: 3px;
}

.nav-element.active {
  background-color: rgb(65, 65, 65);
  border-radius: 3px;
}

.popup-content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}
.popup-links a,
.popup-links p {
  text-decoration: none;
  color: rgb(230, 217, 185);
  color: var(--almost-white);
  font-size: 3rem;
  line-height: 3rem;
  margin: 0;
}
.popup-links a:hover,
.popup-links p:hover {
  color: darkred;
  cursor: pointer;
}

.popup-photo {
  height: 400px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.photo-content img {
  height: 100%;
  width: 100%;
}

.terminal-popup {
  z-index: 1;
  font-family: "Press Start 2P";
  font-size: 0.7rem;

  position: absolute;
  top: 3rem;
  right: 3rem;

  width: 30rem;
  height: 60%;

  display: flex;
  flex-direction: column;

  border-radius: 5px;
  overflow: hidden;

  box-shadow: -2px 2px 3px 1px rgb(0 0 0 / 40%);
  background-color: rgb(0, 0, 0, 1);
  color: white;
}
.terminal-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: rgb(59, 59, 59);
  padding: 0.3rem 0.3rem;
  width: 100%;
}
.terminal-close {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: none;
  background: red;
  color: transparent;
  text-align: center;
}
.terminal-close:hover {
  background: rgb(175, 0, 0);
}
.terminal-content {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.terminal-tag {
  margin: 1rem 0;
}
.terminal-about,
.lyrics {
  padding: 1rem;
}

.song-title,
.lyrics,
.project-title {
  position: relative;
}
.song-title:hover,
.about-bio:hover {
  background: white;
  color: black;
}
.song-title:hover {
  cursor: pointer;
}
.redacted::after {
  display: block;
  position: absolute;
  top: 0;
  content: "[redacted]";
  z-index: 1;
  background: white;
  color: black;
}
.redacted:hover {
  cursor: default;
}
.lyrics {
  position: relative;
}
.lyrics-redacted::after {
  position: absolute;
  top: 0;
  display: none;
  content: "[redacted]";
  z-index: 2;
  width: 100%;
  height: 100%;
}
.lyrics-redacted:after {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
}

.unused-lyrics {
  text-decoration: line-through;
}

.cohort-b {
  position: absolute;
  bottom: 160px;
  left: calc(50% - 41.655px);
  right: calc(50% + 41.655px);
  font-family: prompt;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.announcement-container {
  background: transparent;
  color: rgb(90, 84, 121);
  color: var(--lavender);
  flex-grow: 1;

  overflow: hidden;
}

.announcement-nav {
  width: 100%;
  color: white;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  overflow: hidden;
}
.announcement-nav-element {
  padding: 1rem;
  width: 50%;
  text-align: center;
  border: 1px solid transparent;
}
.announcement-nav-element:hover {
  border: 1px solid #004eb2;
  cursor: pointer;
}
.nav-selected {
  background: #f9769b;
}

.show-content,
.release-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  overflow-y: scroll;
  padding: 1rem 0 4rem 0;
  box-sizing: border-box;
}
.show-info,
.release-info,
.noClick {
  width: 100%;
  margin: 1rem 0;
  border: 1px solid rgb(230, 217, 185);
  border: 1px solid var(--almost-white);
  padding: 1rem;
  color: rgb(230, 217, 185);
  color: var(--almost-white);
  box-sizing: border-box;
}
.show-info:hover,
.release-info:hover {
  background: #f6e5f6;
  color: rgb(28, 28, 28);
  color: var(--almost-black);
  cursor: pointer;
}
.release-info:hover .release-content {
  background-image: url(/static/media/trees.806ce5e8.JPG);
  background-position: center;
}
.noClick:hover,
.noClick:active {
  border: 1px solid red;
  color: red;
  cursor: not-allowed;
}
.show-header {
  font-size: 2rem;
}

.release-img {
  width: 100%;
  height: auto;
  margin-top: 1rem;
}

.what {
  padding: 1rem;
  float: right;
  position: relative;
}
.what:after {
  display: none;
  position: absolute;
  content: "huh?";
  background: darkred;
  color: black;
  top: 0;
  z-index: 5;
  height: 100%;
  width: 100%;
}
.what:hover:after {
  display: flex;
  justify-content: center;
  align-items: center;
}

.break {
  height: 4500px;
}

.epk-nav {
  position: absolute;
  bottom: unset;
  top: 0rem;
  right: 0rem;
  z-index: 5;
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.epk-nav .cohort-logo {
  padding: 0;
  margin: 0;
}

.epk-nav:hover {
  -webkit-filter: unset;
          filter: unset;
}

.epk-nav-elements {
  display: none;
}

.epk-container {
  height: 100%;
  width: 100%;
  background: rgb(218, 60, 50);
  color: rgb(230, 217, 185);
  color: var(--almost-white);
  position: relative;

  overflow-y: scroll;
}

.epk-row {
  min-height: 20rem;
  width: 100%;
  border-bottom: 1px dashed grey;
  padding: 1rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
}

.epk-row-header {
  font-size: 100px;
  padding-right: 20rem;
}

.epk-row-body {
  flex-grow: 2;
  box-sizing: border-box;
  margin: 0 1rem;
}

.epk-row-text-1 {
  font-size: 50px;
}

.epk-row-text-2 {
  font-size: 30px;
  font-weight: 100;
}

.epk-square {
  height: 20rem;
  width: 20rem;
  flex-grow: 1;
}

.epk-link {
  color: rgb(230, 217, 185);
  color: var(--almost-white);
  text-decoration: underline;
}

.epk-link:hover {
  text-decoration: underline;
  color: rgb(28, 28, 28);
  color: var(--almost-black);
  cursor: pointer;
}

.release-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.epk-release-header {
  display: block;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 1rem;
  font-size: 35px;
}

.epk-previous-releases {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1rem;
}

@media (max-width: 1100px) {
  .epk-row {
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  .epk-previous-releases {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 1024px) {
  .mobile-banner {
    position: relative;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
  }
}

/* IPHONE X */
@media only screen and (min-device-width: 280px) and (max-device-width: 1024px) {
  .main-container {
    background-position-x: 0;
    background-position-y: 110%;
    background-size: 500px;
    background-repeat: no-repeat;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-content {
    top: 20%;
    right: unset;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .announcement-container {
    top: 0;
    width: 100%;
    height: 100%;
    left: unset;
    right: unset;
  }

  .mobile-banner {
    position: absolute;
    bottom: 0;
    height: 140px;
    background-color: black;
  }

  .cohort-logo-mobile {
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    -webkit-filter: invert(1);
    filter: invert(1);
  }

  .release-content {
    height: 75%;
  }

  .epk-row {
    display: flex;
    flex-direction: column;
  }
}

.off {
  display: none;
}

/* SCROLLBAR */

::-webkit-scrollbar {
  width: 10px;
  position: absolute;
  right: 0;

  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(230, 217, 185);
  background: var(--almost-white);
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

